import Vue from "vue";
import Alertize from "@/components/Alertize.vue";
import OverView from '@/views/Admin/Segments/SegmentsUpload/Create/overview.vue';
import { Notification, MessageTypes } from "@/interfaces/proccess";
import { SegmentFilter, SegmentOption } from '@/interfaces/segment';
import { isEmpty, isNull, isUndefined } from "lodash";
import { Paginated, SortingOption, ResultPaginate } from "@/interfaces/paginated";
import { SegmentUpload } from '@/interfaces/segment_upload';
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";
import { mapActions } from "vuex";

export default Vue.extend({

	name: "CreateSegmentsUpload",

	props: {},

	components: { Alertize, OverView },

	data: () => ({
		title: "CreateSegmentUpload",
		params_segment: {
			filters: {} as SegmentFilter,
			options: { sort: "name", order: "desc" } as SegmentOption
		},
		params_segment_upload: {
			paginated: { page: 1, limit: 25 } as Paginated,
			filters: {},
			options: { sort: "id", order: "desc" } as SortingOption
		},
		interval: null,
	}),

	created() {
		this.$nextTick(async () => {
			this.setLoadingData(TypeLoading.loading)
			this.setSegmentList();
			this.fetchSegmentUpload();
			await this.setLoadingData();
		});
	},

	async mounted() {
		this.interval = setInterval(() => { this.dispatchSegmentUpload(); }, 30000)
	},

	beforeDestroy() {
		clearInterval(this.interval);
	},

	computed: {

		getSegmentList() {
			return this.$store.state.segment.segment_list;
		},

		getSegmentsUpload() {
			return this.$store.state.segment_upload.segments_upload;
		},

		getResultPaginate(): ResultPaginate {
			return this.$store.state.segment_upload.result_paginate;
		},

		getEntities(): SegmentUpload[] {
			const result: ResultPaginate = this.getResultPaginate;
			if (
				isUndefined(result) ||
				isNull(result) ||
				isUndefined(result.data) ||
				isNull(result.data)
			) {
				return [];
			}
			return result.data;
		},

	},

	methods: {
		...mapActions("loading", ["setLoadingData"]),

		setSegmentList() {
			this.$store.state.segment.segment_list = [];
		},

		updatePaginate(data: any) {
			this.params_segment_upload.paginated.page = data;
		},

		setNotification(notification: Notification) {
			return this.$store.dispatch("proccess/setNotification", notification, {
				root: true
			});
		},

		getMsgSuccess(redirec: boolean, to: string, message: string) {
			let msgSucces = {
				title: "Success",
				message: message,
				type: MessageTypes.SUCCESS,
				show: true,
				details: "",
				to: redirec ? to : undefined,
				btn_text: "Close"
			};
			return msgSucces;
		},

		async createSegmentUpload(params: { formData: FormData }) {
			this.setLoadingData(TypeLoading.loading)
				await this.dispatchCreateSegmentUpload(params)
					.then(async (result) => {
						this.dispatchSegmentUpload();
						this.setNotification(this.getMsgSuccess(false, "", "Your changes have been saved successfully"));
						await this.setLoadingData();
					}).catch(async (err) => {
						this.setNotification({
							title: this.$t("title-failed"),
							message: this.$t('segmentUpload.fields.update_file'),
							btn_text: this.$t("try-again"),
							type: MessageTypes.ERROR,
							show: true,
						})
						await this.setLoadingData();
					});
		},

		//Fetch
		async fetchSegmentList() {
			if (!isEmpty(this.getSegmentList)) return;
			await this.setLoadingData(TypeLoading.loading);
			await this.dispatchSegments();
			await this.setLoadingData();
		},

		async fetchSegmentUpload() {
			//if(!isEmpty(this.getSegmentsUpload)) return;
			await this.setLoadingData(TypeLoading.loading);
			await this.dispatchSegmentUpload();
			await this.setLoadingData();
		},

		// Dispatch Segments
		async dispatchSegments() {
			return await this.$store.dispatch("segment/list", this.params_segment, { root: true, });
		},

		async dispatchCreateSegmentUpload(params: { formData: FormData }) {
			return await this.$store.dispatch("segment_upload/createSegmentsUpload", params, { root: true, });
		},

		async dispatchSegmentUpload() {
			return await this.$store.dispatch("segment_upload/paginated", this.params_segment_upload, { root: true, });
		}
	},
	watch: {
		"params_segment_upload.paginated.page"(val, old) {
			if (val !== old) {
				this.dispatchSegmentUpload();
			}
		}
	}

});
