/**
 * Tootip data
 * @param params
 * @returns
 */

export function prepareTooltipData(params: any) {
	return {
		icon: "mdi-information-outline",
		text: params,
		to: "",
		top: true,
		max_width: "500",
	};
}

/**
 * Prepared Config Input
 * @param params
 * @returns
 */
export function preparedConfig(params: {
	rules: any[];
	items: any[];
	status: Boolean;
	total: number;
	devices: number;
	key: string;
	hint: string;
	label: string;
	multiple: Boolean;
	persistent_hint: Boolean;
	required: Boolean;
	no_data_text: string;
	selected_all: any[];
}) {
	return {
		rules: params.rules || [],
		items: params.items || [],
		additional: {
			status: params.status || false,
			total: params.total || false,
			devices: params.devices || false,
		},
		reference: params.key,
		hint: params.hint || "",
		placeholder: params.label,
		label: params.label,
		multiple: params.multiple || true,
		persistent_hint: params.persistent_hint || false,
		required: params.required || false,
		no_data_text: params.no_data_text,
		selected_all: params.selected_all,
		item_select_all: true,
		item_text: "id",
		item_value: "value",
		hideDetails: true,
	};
}
