
export default {
	name: "Tooltip",
	components: {},
	props: {
		config: {
			type: Object,
			default: function () {
				return {
					to: "",
					text: "",
					icon: "mdi-information-outline",
					right: true,
					left: false,
					top: false,
					bottom: false,
					delay: 100,
					open_on_hover: true,
					max_width: "300"
				};
			},
		},
		apply_class:{
			type: Boolean,
            default: true
		},
		apply_class_rigth:{
			type: Boolean,
            default: false
		}
	},
	data: () => ({}),
	mounted() {},
	created() {},
	computed: {
		getClass(){
			return this.apply_class ? 
				this.apply_class_rigth ? "helps_v2 btn-tooltip" : "helps btn-tooltip"
				: "btn-tooltip"
		}
	},
	methods: {
		handlerClick() {
			this.$emit("click");
		}
	},
	watch: {},
};
