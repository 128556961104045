
import Vue from "vue";
// @ts-ignore
import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
// @ts-ignore
import TableUpload from "@/views/Admin/Segments/SegmentsUpload/Table/tableUpload.vue";
import { isEmpty, isUndefined, isNull } from "lodash";
// @ts-ignore
import { isRequired } from "@/services/rule-services";
import { mapActions, mapGetters } from "vuex";
import {
	preparedColumnDefsGrid,
	// @ts-ignore
} from "@/utils/CustomizeViewData";
// @ts-ignore
import GridTable from "@/components/Header/Tables/GridTable.vue";
// @ts-ignore
import ActionsTable from "@/components/Header/Tables/ActionsTable.vue";
// @ts-ignore
import FilterGridAG from "@/components/Header/Tables/FilterGridAG.vue";
// @ts-ignore
import ActiveFieldTable from "@/components/Header/Tables/ActiveFieldTable.vue";
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";
// @ts-ignore
import ButtonTooltip from "@/components/Content/Commons/ButtonTooltip.vue";
//@ts-ignore
import { prepareTooltipData } from "@/views/Admin/Persons/V4/Dependencies/FormFilters/utils";
//@ts-ignore
import { getPermisionSegmento } from "@/utils/permissionResolve.ts";
//@ts-ignore
import NotPermission from "@/views/Errors/not_permission.vue";

export default Vue.extend({
	name: "Overview",

	props: {
		segment_list: {
			type: Array,
			required: true,
		},
		segment_upload_list: {
			type: Array,
			required: true,
		},
		result_page: {
			type: Object,
			default: function () {
				return {};
			},
		},
		limit: {
			type: Number,
			default: 25,
		},
	},

	components: {
		CardAutocomplete,
		TableUpload,
		GridTable,
		ActionsTable,
		FilterGridAG,
		ActiveFieldTable,
		ButtonTooltip,
		NotPermission
	},

	data: () => ({
		snackbar: false,
		valid: false,
		continent_data: "NAM",
		segment_data: [],
		file_rule: [],
		file_select: undefined,
		resources: {
			continents: [],
		},
		context: null,
		selectionRows: "single",
	}),

	created() {},

	mounted() {
		this.$nextTick(async () => {
			await this.loadResources();
			this.context = { componentParent: this };
		});
	},

	computed: {
		...mapGetters("profile", ["getAbility"]),
		prepareTableHeaders() {
			return [
				{
					text: "Id",
					align: "start",
					sortable: false,
					filterable: false,
					value: "id",
				},
				{
					text: this.$t('segmentUpload.fields.file_name'),
					align: "start",
					sortable: false,
					filterable: false,
					value: "file_name",
				},
				{
					text: this.$t('segmentUpload.fields.upload_complete'),
					align: "start",
					sortable: false,
					filterable: false,
					value: "upload_complete_date",
				},
				{
					text: this.$t('segmentUpload.fields.upload_message'),
					align: "start",
					sortable: false,
					filterable: false,
					value: "upload_message",
				},
				{
					text: this.$t('segmentUpload.fields.create_date'),
					align: "start",
					sortable: false,
					filterable: false,
					value: "create_date",
				},
				{
					text: this.$t('segmentUpload.fields.upload_status'),
					align: "start",
					sortable: false,
					filterable: false,
					value: "upload_status",
				},
			];
		},

		prepareTableContent() {
			const segments_upload = this.segment_upload_list;
			if (isUndefined(segments_upload) || isNull(segments_upload))
				return [];
			return segments_upload.map((segments: any) => {
				return {
					id: segments.id,
					//file_name: segments.s3_filename,
					file_name: this.getOnlyName(segments.gcp_filename),
					upload_complete_date: this.moment(
						segments.updated_at
					).format("YYYY-MM-DD HH:mm:ss"),
					upload_message: segments.status,
					create_date: this.moment(segments.created_at).format(
						"YYYY-MM-DD HH:mm:ss"
					),
					upload_status: segments.status,
				};
			});
		},

		getRules() {
			return {
				isRequired,
			};
		},

		getTotalSegmentUpload() {
			return this.$t("show.segment_upload", {
				total: this.segment_upload_list.length,
			});
		},

		getResultPage() {
			return this.result_page;
		},

		getContinentes() {
			return this.resources.continents;
		},

		getConfigColumnDef() {
			return {
				context: this.context,
				entityName: "Segments Upload",
				eventActions: false,
				flex: 1,
				resizableID: true,
				permission: this.getPermission
			};
		},

		getColumnsDef() {
			return preparedColumnDefsGrid(
				this.prepareTableHeaders,
				this.getConfigColumnDef
			);
		},

		gerRowsData() {
			if (isEmpty(this.table.items)) {
				return [];
			} else {
				return this.table.items;
			}
		},

		canList(){
			return this.getAbility.can(this.getPermission.actions.index_upload, this.getPermission.subject);
		},

		canCreate(){
			return this.getAbility.can(this.getPermission.actions.create_upload, this.getPermission.subject);
		},

		getPermission(){
			return getPermisionSegmento();
		}
	},

	methods: {
		...mapActions("loading", ["setLoadingData"]),

		...mapActions("segment_upload", ["continentsList"]),

		async loadResources() {
			this.resources.continents = await this.continentsList();
		},

		updatePaginate(data: any) {
			this.$emit("update-paginate", data);
		},

		clearRules() {
			this.file_rule = [];
		},

		clearSegmentUpload() {
			this.continent_data = [];
			this.segment_data = undefined;
			this.file_select = undefined;
		},
		/**
		 * Tootip data
		 * @param params
		 * @returns
		 */
		tooltipData(params: any) {
			return prepareTooltipData(params);
		},

		handleCancel() {
			this.$router.push({ name: "CampaignsIndex" });
		},

		clearContinente() {
			this.continent_data = "NAM";
		},

		async fetchSegmentList(e: any) {
			if (!isEmpty(this.segment_list)) return;
			this.$emit("fetch-segment-list");
		},

		async validate() {
			let form = this.$refs.form;
			const valid = await form.validate();
			return await valid;
		},

		async addRulesSegmentUpload() {
			this.file_rule = [this.getRules.isRequired];
		},

		async handleSubmit() {
			try {
				if (this.segment_data.length <= 0) {
					this.snackbar = true;
					return;
				}
				this.snackbar = false;
				await this.addRulesSegmentUpload();
				if (!(await this.validate())) return;
				await this.saveSegmentUpload();
				this.clearRules();
			} catch (error) {
				console.error("handleSubmit", error);
			}
		},

		async saveSegmentUpload() {
			let formData = new FormData();
			formData.append("file", this.file_select);
			formData.append("continent", this.continent_data);
			for (var i = 0; i < this.segment_data.length; i++) {
				formData.append("segment_ids[]", this.segment_data[i]);
			}
			await this.$emit("create-segment-upload", { formData });
			this.clearSegmentUpload();
		},

		getOnlyName(fileName: String) {
			let name = "";
			if (isUndefined(fileName) || isNull(fileName)) {
				return name;
			}
			let parts = fileName.split("/");
			if (!(parts.length > 0)) {
				return fileName;
			}
			name = parts[parts.length - 1];
			return name;
		},
	},
});
