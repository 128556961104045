 
	import Vue from "vue";

	export default Vue.extend({
		name: "TableUpload",

		props: {
			current_page: {
				type: Number,
			},
			next_page_url: {
				type: String,
			},
			path: {
				type: String,
			},
			per_page: {
				type: Number,
			},
			prev_page_url: {
				type: String,
			},
			to: {
				type: Number,
			},
			total: {
				type: Number,
			},
            headers: {
				type: Array,
				default: [],
			},
			items: {
				type: Array,
				default() {
                    return []
                }
			},
			limit: {
				type: Number,
				default: 25,
			},
        },

		components: {},
		data: () => ({}),
		created() {},
		mounted() {},
		computed: {
			currentPage: {
				set(val) {
					this.$emit("update-current-page", val);
				},
				get() {
					return this.current_page;
				},
			},

			getLength() {
				return Math.ceil(this.total / this.per_page);
			},
		},
		methods: {
			updatePaginate(data: Number) {
				this.$emit("update-paginate", data);
			},
		},
	});
